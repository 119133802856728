<template>
  <div class="overall">
    <div class="content">
      <!-- 检索条件 -->
      <!-- <el-row :gutter="20">
        <el-col :span="6">
          <el-form label-width="60px">
            <el-form-item label="门店">
                <el-input placeholder="请输入门店名称" v-model.trim="name"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
            <el-button type="primary" @click="seach()">查询</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
      </el-row> -->
      <div style="padding-top: 10px">
        <!-- <el-button type="primary" @click="handleExport()">导出</el-button> -->
        <el-button type="primary" @click="handleRules()">新增打卡规则</el-button>
      </div>

      <!-- table -->
      <div class="tableBox">
            <el-table
                :data="tableData"
                v-loading="tableLoading"
                max-height="650"
                border
                ref="multipleTable"
                style="width: 100%">
                <!-- <el-table-column
                type="selection"
                width="55">
                </el-table-column> -->
                <el-table-column
                    prop="createAt" 
                    label="创建时间"
                    width="">
                </el-table-column>
                <el-table-column
                    prop="city" 
                    label="城市"
                    width="">
                </el-table-column>
                <el-table-column
                    prop="siteName" 
                    label="门店"
                    width="">
                </el-table-column>
                <el-table-column
                   prop="type" 
                   label="类型">
                   <template slot-scope="scope">
                     {{scope.row.type === 1 ? '兼职' : '全职'}}
                   </template>
               </el-table-column>
                <el-table-column
                    prop="gowork" 
                    label="上班打卡"
                    width="">
                </el-table-column>
                <el-table-column
                    prop="afterwork" 
                    label="下班打卡"
                    width="">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="200">
                    <template slot-scope="scope">
                        <!-- <el-button
                        @click.native.prevent="handleEdit(scope.$index, scope.row)"
                        type="primary">
                        编辑
                        </el-button> -->
                        <el-button
                        @click.native.prevent="handleDelete(scope.$index, scope.row)"
                        type="danger">
                        删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
<!-- 分页 -->
        <div class="paginationBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            background
            :total="total">
          </el-pagination>
        </div>
        <!-- 新增以及编辑弹框 -->
        <el-dialog
            title="新增规则"
            :visible.sync="updateVisible"
            :before-close="beforeClose"
            width="30%">
            <el-form 
                ref="form"
                :model="form"
                :rules="rules"
                label-width="120px">
                <el-form-item label="城市" prop="city">
                    <el-input placeholder="请输入城市" v-model.trim="form.city"></el-input>
                </el-form-item>
                <el-form-item label="门店" prop="siteId">
                <el-select v-model="form.siteId" placeholder="请选择门店" style="width: 100%" :clearable="true">
                  <el-option
                    v-for="item in siteIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="工作类型" prop="type">
                  <el-select v-model.trim="form.type" style="width: 100%" placeholder="请选择">
                  <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
             </el-form-item>
                <el-form-item label="上班打卡时间" prop="gowork">
                  <el-time-picker
                      v-model="form.gowork"
                      style="width: 100%"
                      value-format="HH:mm"
                      placeholder="请输入上班打卡时间">
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="下班打卡时间" prop="afterwork">
                    <el-time-picker
                      v-model="form.afterwork"
                      style="width: 100%"
                      value-format="HH:mm"
                      placeholder="请输入下班打卡时间">
                    </el-time-picker>
                </el-form-item>
                
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose()">取 消</el-button>
                <el-button type="primary" @click="handleConfirm()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
  </div>
</template>

<script>
import { getList,getExport,update,remove} from "../../../api/clockinRules";
import { siteInfoList,  } from '../../../api/restaurant';
export default {
  data(){
    return{
      option:[
          {value:1,label:'兼职'},
          {value:2,label:'全职'}
      ],
      ids:[],
      currentPage:1,
      pageSize:10,
      total:0,
      tableData: [],
      tableLoading: true,
      updateVisible: false,
      form:{
        city : "",
        gowork : null,
        afterwork : null,
        siteId: "",
        type: "",
      },
      siteIdList:[],
       // 前台验证
       rules:{
        city: [
            { required: true, message: '城市不能为空', trigger: 'blur' },
        ],
        gowork: [
            { required: true, message: '上班打卡时间不能为空', trigger: 'blur' },
        ],
        afterwork: [
            { required: true, message: '下班打卡时间不能为空', trigger: 'blur' },
        ],
        siteId: [
            { required: true, message: '门店不能为空', trigger: 'blur' },
        ],
        type: [
            { required: true, message: '工作类型不能为空', trigger: 'blur' },
        ],
    }
      
    }

  },
  // 1.页面初始化方法
  mounted(){
    
    this.clockinList();
  },
  //方法
  methods: {
    // 列表
    async clockinList(){
      try {
        const params = {
         page:this.currentPage,
         limit:this.pageSize
        }
       this.tableLoading = true;
       let data =  await getList(params);
       this.total = data.total;
       this.tableData = data.punchinList;
      } catch (error) {
        console.log("error",error)
      }
      this.tableLoading = false;
    },
    //新增规则
    async clockinRulesUpdate(){
      try {
            const params={
              ...this.form
            }
            await update(params);
            this.$message({
                      showClose: true,
                      type: "success",
                      message: "操作成功!"
                });
            this.clockinList();
          } catch (error) {
            console.log("error",error);
          }
          this.updateVisible = false;
    },
    //删除
    handleDelete(index,row) {
            let id = row.id
           this.$confirm("此操作将永久删除该数据, 是否继续?", {
               confirmButtonText: "确定",
               cancelButtonText: "取消",
               type: "warning"
           }).then(() => {
               const params = {
                id:id,
                status:2
               };
               return remove(params);
           }).then(() => {
               this.clockinList();
               this.$message({
                    showClose: true,
                    type: "success",
                    message: "操作成功!"
               });
            }).catch(() => {
              this.$message({
              type: 'info',
              message: '已取消删除'
              });          
            });
       },
  
    handleSizeChange(size) {
        this.pageSize = size;
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.siteList();
    },
      // 门店下拉
      async siteInfoList() {
      try {
        let data = await siteInfoList();
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.id,
            label: item.name
          })
        })
        this.siteIdList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },
    //打卡规则按钮打开弹窗
    handleRules(){
      //下拉默认是1：兼职
      this.form.type=this.option[0].value;
      //门店下拉
      this.siteInfoList();
      //显示弹窗
      this.updateVisible = true;
    },
    //弹窗取消
    beforeClose() {
      this.updateVisible = false;
      this.$refs['form'].resetFields();
      //下拉默认是1：兼职
      this.form.type=this.option[0].value;
    },
      //新增打卡规则弹窗确定按钮
      handleConfirm(){
          this.$refs['form'].validate((valid) => {
            if(valid){
              var goworkTime=this.form.gowork.split(":");
              var afterTime=this.form.afterwork.split(":");
              //先比较小时的大小
              if(goworkTime[0] < afterTime[0]){
                //上班时间小于下班时间
                this.clockinRulesUpdate();
              }else if(goworkTime[0]==afterTime[0]){
                //上班时间小于下班时间的小时相同比较分钟
                if(goworkTime[1]<afterTime[1]){
                  this.clockinRulesUpdate();
                }else{
                  this.$message({
                      showClose: true,
                      type: "warning",
                      message: "下班打卡时间不能晚于上班打卡时间!"
                  });
                }
              }else{
                this.$message({
                      showClose: true,
                      type: "warning",
                      message: "下班打卡时间不能晚于上班打卡时间!"
                });
              }
            }else{
              console.log("error submit!")
              return false;
            }
          });
        },

    // 导出数据
    handleExport() {
           this.$confirm("确认导出数据?", {
               confirmButtonText: "确定",
               cancelButtonText: "取消",
               type: "warning"
           }).then(() => {
               const params = {
                page:this.currentPage,
                limit:this.pageSize,
                ids:this.ids
               };
               return getExport(params);
           }).then(() => {
               this.clockinList();
               this.$message({
                    showClose: true,
                    type: "success",
                    message: "操作成功!"
               });
            }).catch(() => {
              this.$message({
              type: 'info',
              message: '已取消导出'
              });          
            });
       },

    //搜索
    // seach() {
    //   if (this.name === "") {
    //     this.$message({
    //       message: '请输入门店名称',
    //       type: 'warning'
    //     });
    //     return;
    //   }
    //   this.clockinList();
    // },
    // 重置
    // reset() {
    //   this.name = "";
    //   this.currentPage = 1;
    //   this.clockinList();
    // },

    //复选框
    // handleSelectionChange(selectionList) {
    //         this.ids = [];
    //         selectionList.forEach(ele => {
    //         this.ids.push(ele.id);
    //     });
    //     return this.ids.join(",");
    //   },
  }
}
</script>

<style lang="scss" scoped>
.overall {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>