import http from "../utils/http";
import { stringify } from 'qs';

export function getList(params) {
    return http.get("/punchin/list?" + stringify(params));
}

export function update(params) {
    return http.post("/punchin/add?" + stringify(params));
}

export function remove(params) {
    return http.get("/punchin/delete?" + stringify(params));
}

export function getExport(params) {
    return http.get("/zJob/update?" + stringify(params));
}

